<template>
    <div class="legal nav-spcr">
        <section class="post">
            <div>
                <h1 class="font-weight-bold">Terms of Service</h1>
                <h5>Effective as of October 10, 2019</h5>
            </div>

            <p class="container">
                <br><strong>Welcome to Sypher!</strong><br>
                This Sypher User Agreement ("Terms") applies to your access to and use of the websites, mobile apps,
                widgets, and other online products and services (collectively, the "Services") provided by Sypher Music ("Sypher," "we," or "us"). When you create a Sypher account or use Sypher, you agree to these terms.

                <br><br><strong>Privacy</strong><br>

                Our Privacy Policy <router-link to="/privacy">(https://sypher.app/privacy)</router-link> describes how we handle the
                information you provide to us when you use our Services. You understand that through your use of the
                Services you consent to the collection and use (as set forth in the Privacy Policy) of this information.

                <br><br><strong>Your Access to the Services</strong><br>

                Children under the age of 13 are not allowed to create an account or otherwise use the Services.
                Additionally, if you are in the European Economic Area, you must be over the age required by the laws of
                your country to create an account or otherwise use the Services, or we need to have received verifiable
                consent from your parent or legal guardian.<br><br>

                In addition, certain of our Services or portions of our Services require you to be older than 13 years
                of age, so please read all notices and any Additional Terms carefully when you access the
                Services.<br><br>

                If you are accepting these Terms on behalf of another legal entity, including a business or a
                government, you represent that you have full legal authority to bind such entity to these terms.

                <br><br><strong>Your Use of the Services</strong><br>

                Sypher grants you a personal, non-transferable, non-exclusive, revocable, limited license to use and
                access the Services solely as permitted by these Terms. We reserve all rights not expressly granted to
                you by these Terms.<br><br>
                Except as permitted through the Services or as otherwise permitted by us in writing, your license does
                not include the right to:

                <ul style="list-style-type:disc; text-align:left;">
                    <li>license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the
                        Services or Content;</li>
                    <li>modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the
                        Services or Content; or</li>
                    <li>access the Services or Content in order to build a similar or competitive website, app, product,
                        or service.</li>
                </ul>

                We reserve the right to modify, suspend, or discontinue the Services (in whole or in part) at any
                time, with or without notice to you. Any future release, update, or other addition to functionality
                of the Services will be subject to these Terms, which may be updated from time to time. You agree
                that we will not be liable to you or to any third party for any modification, suspension, or
                discontinuation of the Services or any part thereof.



                <br><br><strong>Your Listening Activity</strong><br>

                When you use the Services, we keep track of your listening activity, including the number and titles
                of songs to which you have listened, the songs, albums, or artists that you share with others, and
                the stations you create or listen to. We may also keep track of your interactions with the Services,
                which may include the features you use, the advertising in which you see or show interest, and the
                content you view. We do this for a variety of reasons, such as to gain an understanding of the types
                of music, content, or features you and/or other similar listeners tend to like or dislike, for
                compensating artists and other rightsholders for use of their content, to provide you with relevant
                and interesting advertising, and to improve the Services generally. As this data is essential to the
                function of the Services, you may not opt out of our collection and use of such data or information.
                By accessing or otherwise using any portion of the Services, you hereby consent to the foregoing
                collection and use of your listening activity and behavior for the purposes set forth above and also
                as outlined in our <router-link to="/privacy">Privacy Policy</router-link>.

                <br><br><strong>Your Sypher Account and Account Security</strong><br>

                To use certain features of our Services, you may be required to create a Sypher account (an
                "Account") and provide us with a username, password, and certain other information about yourself as
                set forth in the <router-link to="/privacy">Privacy Policy</router-link>.<br><br>
                You are solely responsible for the information associated with Your Account and anything that
                happens related to Your Account. You must maintain the security of your Account and promptly notify
                Sypher if you discover or suspect that someone has accessed your Account without your permission. We
                recommend that you use a strong password that is used only with the Services.<br><br>
                You will not license, sell, or transfer your Account without our prior written approval.

                <br><br><strong>Content</strong><br>

                You are responsible for your use of the Services and for any Content you provide, including
                compliance with applicable laws, rules, and regulations. You should only provide Content that you
                are comfortable sharing with others.<br><br>
                Any use or reliance on any Content or materials posted via the Services or obtained by you through
                the Services is at your own risk. We do not endorse, support, represent or guarantee the
                completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the
                Services or endorse any opinions expressed via the Services. You understand that by using the
                Services, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise
                inappropriate, or in some cases, postings that have been mislabeled or are otherwise deceptive. All
                Content is the sole responsibility of the person who originated such Content. We may not monitor or
                control the Content posted via the Services and, we cannot take responsibility for such
                Content.<br><br>
                We reserve the right to remove Content that violates the User Agreement, including for example,
                copyright or trademark violations, impersonation, unlawful conduct, or harassment. <br><br>
                If you believe that your Content has been copied in a way that constitutes copyright infringement,
                please report this by visiting our contact form <router-link to="/contact">(https://sypher.app/contact)</router-link>.


                <br><br><strong>The Services are Available "AS-IS"</strong><br>

                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
                OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SYPHER, ITS LICENSORS, AND ITS THIRD PARTY SERVICE
                PROVIDERS DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE.
                SYPHER DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO
                THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE SYPHER ATTEMPTS
                TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR
                SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.



                <br><br><strong>Limitation of Liability</strong><br>

                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SYPHER ENTITIES SHALL NOT BE LIABLE FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
                REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER
                INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
                SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT
                LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY
                CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
                TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE SYPHER ENTITIES EXCEED
                THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID SYPHER, IF ANY, IN THE
                PAST SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SUBSECTION SHALL
                APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING
                NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE SYPHER ENTITIES HAVE BEEN INFORMED OF THE
                POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
                ESSENTIAL PURPOSE.

                <br><br><strong>WE RESERVE THE RIGHT AT ANY TIME TO:</strong><br>
                <ul style="list-style-type:disc; text-align:left;">
                    <li>Change the terms and conditions of this Agreement;</li>
                    <li>Change the Site, including eliminating or discontinuing any content on or feature of the
                        Site; or</li>
                    <li>Change any fees or charges for use of the Site.</li>
                </ul>

                Any changes we make will be effective immediately upon notice, which we may provide by means
                including, without limitation, posting on the Site or electronic mail. Your continued use of the
                Site following such changes will be deemed acceptance of such changes. Be sure to return to this
                page periodically to ensure familiarity with the most current version of this Agreement.

                <br><br><strong>Termination</strong><br>

                You may terminate these Terms at any time and for any reason by deleting your Account and
                discontinuing your use of all Services. If you stop using the Services without deactivating your
                Accounts, your Accounts may be deactivated due to prolonged inactivity.<br><br>
                We may suspend or terminate your Accounts, status as a moderator, or ability to access or use
                the Services at any time for any or no reason, including for a violation of these Terms

                <br><br><strong>Miscellaneous</strong><br>
                These Terms constitute the entire agreement between you and us regarding your access to and use
                of the Services. Our failure to exercise or enforce any right or provision of these Terms will
                not operate as a waiver of such right or provision. If any provision of these Terms is, for any
                reason, held to be illegal, invalid or unenforceable, the rest of the Terms will remain in
                effect. You may not assign or transfer any of your rights or obligations under these Terms
                without our consent. We may freely assign these Terms.<br><br>
            </p>

        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>